import type { Row } from "@tanstack/react-table";
import { truncate } from "lodash";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useSnippetProgress } from "./hooks/useSnippetProgress";
import type { SnippetProp } from "./queries";

export const SnippetTitleCell = ({
  row,
  communityBotProfileId,
}: {
  row: Row<SnippetProp>;
  communityBotProfileId: number;
}) => {
  useSnippetProgress({
    snippetId: row.original?.id,
    communityBotProfileId,
  });

  return (
    <div
      id={`snippet-title-cell-${communityBotProfileId}-${row.original?.id}`}
      className="flex items-center gap-2"
    >
      <div className="flex flex-col">
        <Typography.LabelSm weight="semibold">
          {truncate(row.original?.title, { length: 40 })}
        </Typography.LabelSm>
      </div>
    </div>
  );
};

/* Version with loading states for future PR:
import { useEffect, useState } from "react";
import type { Row } from "@tanstack/react-table";
import { truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useSnippetProgress } from "./hooks/useSnippetProgress";
import type { SnippetProp } from "./queries";

export const SnippetTitleCell = ({
  row,
  communityBotProfileId,
}: {
  row: Row<SnippetProp>;
  communityBotProfileId: number;
}) => {
  const { status, progress } = useSnippetProgress({
    snippetId: row.original?.id,
    communityBotProfileId,
  });

  const isInProgress = status !== "done" && progress !== 100;
  const [wasEverInProgress, setWasEverInProgress] = useState(false);
  const [isCheckmarkVisible, setIsCheckmarkVisible] = useState(false);
  const [isProgressInfoVisible, setIsProgressInfoVisible] = useState(false);

  useEffect(() => {
    if (isInProgress) {
      setWasEverInProgress(true);
      setIsProgressInfoVisible(true);
      setIsCheckmarkVisible(false);
      return undefined;
    }
    if (wasEverInProgress) {
      // When transitioning from inProgress to done, show checkmark and start fade out
      setIsCheckmarkVisible(true);

      // Start both animations at the same time
      const timer = setTimeout(() => {
        setIsCheckmarkVisible(false);
        setIsProgressInfoVisible(false);
      }, 500);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [isInProgress, wasEverInProgress]);

  if (!wasEverInProgress && !isInProgress) {
    return (
      <div className="flex items-center gap-2">
        <div className="flex flex-col">
          <Typography.LabelSm weight="semibold">
            {truncate(row.original?.title, { length: 40 })}
          </Typography.LabelSm>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      {isInProgress && (
        <Icon type="loader" size={20} useWithStrokeCurrentColor />
      )}
      {isCheckmarkVisible && !isInProgress && (
        <div
          className="transition-all duration-500 ease-in-out"
          style={{ width: isCheckmarkVisible ? "20px" : "0" }}
        >
          <Icon type="check" size={20} className="text-green-500" />
        </div>
      )}
      <div className="flex flex-col">
        <Typography.LabelSm weight="semibold">
          {truncate(row.original?.title, { length: 40 })}
        </Typography.LabelSm>
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${isProgressInfoVisible ? "max-h-6" : "max-h-0"}`}
        >
          <Typography.BodyXs>
            {t(
              `community_bot.bot_builder.knowledge_page.content_loading_states.${status}`,
            )}
            : {progress}%
          </Typography.BodyXs>
        </div>
      </div>
    </div>
  );
}
*/
