import { useQuery } from "react-query";
import { internalApi } from "@/react/helpers/urlHelpers";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";

interface ProgressResponse {
  status: string;
  progress: number;
}

interface UseSnippetProgressProps {
  snippetId: number;
  communityBotProfileId: number;
}

const POLLING_INTERVAL = 300;
const QUERY_KEY = "snippetProgress";

export const useSnippetProgress = ({
  snippetId,
  communityBotProfileId,
}: UseSnippetProgressProps) => {
  const { data, isError } = useQuery<ProgressResponse>(
    [QUERY_KEY, snippetId, communityBotProfileId],
    () =>
      reactQueryGet(
        internalApi.communityBot.customResources.progress({
          params: {
            snippetId,
            community_bot_profile_id: communityBotProfileId,
          },
        }),
      ),
    {
      enabled: !!snippetId && !!communityBotProfileId,
      refetchInterval: data => {
        if (data && data.status === "done" && data.progress === 100) {
          return false;
        }
        return POLLING_INTERVAL;
      },
      // Keep successful responses in cache for 5 minutes
      staleTime: 5 * 60 * 1000,
      // Don't refetch on window focus for completed snippets
      refetchOnWindowFocus: query => {
        const data = query.state.data;
        return !data || data.status !== "done" || data.progress !== 100;
      },
    },
  );

  return {
    progress: data?.progress ?? 0,
    status: data?.status ?? "",
    isPolling:
      !isError && (!data || (data.status !== "done" && data.progress !== 100)),
  };
};
