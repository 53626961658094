import { useState } from "react";
import type { Row } from "@tanstack/react-table";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Loader } from "@/react/components/shared/uikit/Loader";
import { useToast } from "@/react/components/shared/uikit/ToastV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { CheckboxCell } from "./CheckboxCell";
import { CheckboxColumnHeader } from "./CheckboxColumnHeader";
import { EmptyState } from "./EmptyState";
import { useImportSnippetsModal } from "./ImportSnippetsModal";
import { SnippetDropdownActions } from "./SnippetDropdownActions";
import { SnippetTitleCell } from "./SnippetTitleCell";
import { TableList } from "./TableList";
import { TypeBadge } from "./TypeBadge";
import { useSnippetsForKnowledge } from "./queries";
import type { SnippetProp } from "./queries";
import { useMultiSelect } from "./useMultiSelect";

const snippetColumns = ({
  onRemove,
  allIdsOnPage,
  handleSelectAllOnPage,
  handleCheckboxChange,
  selectedIds,
  communityBotProfileId,
}: {
  onRemove: (id: number) => void;
  allIdsOnPage: number[];
  handleSelectAllOnPage: (checked: boolean) => void;
  handleCheckboxChange: (id: number) => void;
  selectedIds: number[];
  communityBotProfileId: number;
}) => [
  {
    header: (
      <CheckboxColumnHeader
        allOnPageSelected={
          allIdsOnPage.length > 0 &&
          allIdsOnPage.every(id => selectedIds.includes(id))
        }
        handleSelectAllOnPage={handleSelectAllOnPage}
      />
    ),
    accessorKey: "checkbox",
    cellClassName: "align-middle gap-0 !px-0",
    thClassName: "!px-0 w-4",
    canSort: false,
    cell: ({ row }: { row: Row<SnippetProp> }) => (
      <CheckboxCell
        row={row}
        handleCheckboxChange={handleCheckboxChange}
        selectedIds={selectedIds}
      />
    ),
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.custom_resources_table.columns.title",
    ),
    accessorKey: "title",
    cell: ({ row }: { row: Row<SnippetProp> }) => (
      <SnippetTitleCell
        row={row}
        communityBotProfileId={communityBotProfileId}
      />
    ),
    canSort: false,
    cellClassName: "w-2/3",
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.custom_resources_table.columns.type",
    ),
    accessorKey: "type",
    cell: ({ row }: { row: Row<SnippetProp> }) => (
      <TypeBadge type={row.original?.type || "Snippet"} />
    ),
    canSort: false,
  },
  {
    header: t(
      "community_bot.bot_builder.knowledge_page.custom_resources_table.columns.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: ({ row }: { row: Row<SnippetProp> }) => (
      <Typography.LabelSm weight="regular">
        {row.original?.used_in_replies}
      </Typography.LabelSm>
    ),
    canSort: false,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row }: { row: Row<SnippetProp> }) => (
      <SnippetDropdownActions snippet={row.original} onRemove={onRemove} />
    ),
  },
];

interface SnippetsTabProps {
  communityBotProfileId: number;
}

export const SnippetsTab = ({ communityBotProfileId }: SnippetsTabProps) => {
  const { watch, setValue } = useFormContext();
  const [snippet_ids] = watch(["snippet_ids"]);
  const [page, setPage] = useState(1);
  const toastr = useToast();
  const snippetsQuery = useSnippetsForKnowledge({
    currentPage: page,
    snippetIds: snippet_ids,
  });
  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    snippetsQuery.remove();
  };
  const {
    selectedIds,
    allIdsOnPage,
    handleCheckboxChange,
    handleSelectAllOnPage,
    reset,
  } = useMultiSelect({ query: snippetsQuery });

  const snippetsCount = snippetsQuery?.data?.count || 0;
  const importSnippetsModal = useImportSnippetsModal();

  const openImportSnippetsModal = () => {
    void importSnippetsModal.show({
      snippetIds: snippet_ids,
      onImport: (ids: number[]) => {
        setValue("snippet_ids", [...snippet_ids, ...ids]);
        toastr.success(
          t(
            "community_bot.bot_builder.knowledge_page.custom_resources_table.import_success",
            {
              count: ids.length,
            },
          ),
        );
      },
    });
  };

  const removeSelected = () => {
    setValue(
      "snippet_ids",
      snippet_ids.filter(
        (snippetId: number) => !selectedIds.includes(snippetId),
      ),
    );
    reset();
    toastr.success(
      t(
        "community_bot.bot_builder.knowledge_page.custom_resources_table.remove_selected_success",
        {
          count: selectedIds.length,
        },
      ),
    );
  };

  if (snippetsQuery.isLoading) {
    return <Loader />;
  }

  if (snippetsCount === 0) {
    return <EmptyState onPrimaryClick={openImportSnippetsModal} />;
  }

  return (
    <>
      <div className="flex min-h-[1.75rem] items-center justify-between gap-4">
        {selectedIds.length > 0 ? (
          <div className="flex items-center gap-4">
            <Typography.LabelMd weight="semibold">
              {t("community_bot.bot_builder.knowledge_page.selected_count", {
                selected_count: selectedIds.length,
                count: snippetsCount,
              })}
            </Typography.LabelMd>
            <Button type="button" variant="secondary" onClick={removeSelected}>
              {t("community_bot.bot_builder.knowledge_page.remove_selected")}
            </Button>
          </div>
        ) : (
          <Typography.LabelMd weight="semibold">
            {t(
              "community_bot.bot_builder.knowledge_page.custom_resources_table.count",
              {
                count: snippetsCount,
              },
            )}
          </Typography.LabelMd>
        )}
        <Button
          type="button"
          onClick={openImportSnippetsModal}
          variant="secondary"
        >
          {t("community_bot.bot_builder.knowledge_page.import")}
        </Button>
      </div>
      <TableList
        columns={snippetColumns({
          onRemove: (id: number) => {
            setValue(
              "snippet_ids",
              snippet_ids.filter((snippetId: number) => snippetId !== id),
            );
          },
          allIdsOnPage,
          handleCheckboxChange,
          handleSelectAllOnPage,
          selectedIds,
          communityBotProfileId,
        })}
        query={snippetsQuery}
        onChangePage={onChangePage}
      />
    </>
  );
};
